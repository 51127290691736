import React, { useContext, useState, useEffect } from 'react';
import './Store.css';
import { Link, useNavigate } from 'react-router-dom';
import { StateContext } from '../StateProvider';
import { Carousel as BootstrapCarousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import storeBanner1 from '../../assets/Store/storeBanner1.png';
import storeBanner2 from '../../assets/Store/storeBanner2.png';
import storeBanner3 from '../../assets/Store/storeBanner3.png';

import hotelIcon from '../../assets/Landing/hotelIcon.png';
import daycareIcon from '../../assets/Landing/daycareIcon.png';
import groomingIcon from '../../assets/Landing/groomingIcon.png';
import adventureIcon from '../../assets/Landing/adventureIcon.png';
import shuttleIcon from '../../assets/Landing/shuttleIcon.png';
import trainingIcon from '../../assets/Landing/trainingIcon.png';
import apparelsIcon from '../../assets/Landing/apparelsIcon.png';
import homesIcon from '../../assets/Landing/homesIcon.png';
import foodIcon from '../../assets/Landing/foodIcon.png';

import wellnessIcon from '../../assets/Landing/wellnessIcon.png';
import giftcardIcon from '../../assets/Landing/giftcardIcon.png';
import friendsIcon from '../../assets/Landing/friendsIcon.png';
import walletIcon from '../../assets/Landing/walletIcon.png';
import rescueIcon from '../../assets/Landing/rescueIcon.png';

import inviteIcon from '../../assets/Landing/inviteIcon.png';
import arrowIcon from '../../assets/Landing/arrowIcon.png';

import impactIcon from '../../assets/Landing/impactIcon.png';

import Footer from './../Footer';

const Store = () => {
  const navigate = useNavigate();
  const { 
    loggedIn,
    setBookingData,
    financialSummary
  } = useContext(StateContext);
  const [totalAnimalsHelped, setTotalAnimalsHelped] = useState(0);

  useEffect(() => {
      if (financialSummary && financialSummary.totalRevenue) {
          setTotalAnimalsHelped(calculateAnimalsHelped(financialSummary.totalRevenue));
      }
  }, [financialSummary]);

  const bannerUrls = {
    "Hero 1": "/profile/membership",
    "Hero 2": "/store/services/grooming",
    "Hero 3": "/store/services/shuttle",
  };

  // Define the function to calculate animals helped
  const calculateAnimalsHelped = (totalRevenue, supportPerAnimal = 20) => {
    const allocatedAmount = totalRevenue * 0.10; // 10% of totalRevenue allocated for animal support
    return Math.floor(allocatedAmount / supportPerAnimal); // Calculate animals helped
  };

  const handleImageClick = (event) => {
    const { alt } = event.target; // Get the alt text of the clicked image
    const url = bannerUrls[alt]; // Determine the URL based on the alt text
    if (url) {
      navigate(url); // Navigate to the determined URL
      window.scrollTo(0, 0);
    }
  };

  const handleMenuButtonClicked = (page) => {
    navigate(`${page}`);
    window.scrollTo(0, 0);
    setBookingData(prevData => ({
      ...prevData,
      bookingType: page
    }));
    localStorage.setItem('bookingType', page);
  }

  const handleDealCardClicked = (page) => {
    navigate(`/${page}`);
    window.scrollTo(0, 0);
  }

  return (
    <div>

      <section className="store-banner-section">
        <BootstrapCarousel fade controls={false} indicators={false} interval={5000}>
          <BootstrapCarousel.Item>
            <img className="d-block w-100" src={storeBanner1} alt="Hero 1" onClick={handleImageClick} />
          </BootstrapCarousel.Item>
          <BootstrapCarousel.Item>
            <img className="d-block w-100" src={storeBanner2} alt="Hero 2" onClick={handleImageClick} />
          </BootstrapCarousel.Item>
          <BootstrapCarousel.Item>
            <img className="d-block w-100" src={storeBanner3} alt="Hero 3" onClick={handleImageClick} />
          </BootstrapCarousel.Item>
        </BootstrapCarousel>
      </section>

      <div className="store-container">
        <div className="container store-menu-container">
          <div className="row">
            <div className="col store-menu-button-container">
              <div className='store-button-image-container'>
                <img className="store-menu-button-image" src={hotelIcon} alt="Hotel Icon" onClick={() => handleMenuButtonClicked('store/services/hotel')} />
              </div>
              <p className="store-menu-button-title">Hotel</p>
            </div>
            <div className="col store-menu-button-container">
              <div className='store-button-image-container'>
                <img className="store-menu-button-image" src={daycareIcon} alt="Daycare Icon" onClick={() => handleMenuButtonClicked('store/services/daycare')} />
              </div>
              <p className="store-menu-button-title">Daycare</p>
            </div>
            <div className="col store-menu-button-container">
              <div className='store-button-image-container'>
                <img className="store-menu-button-image" src={groomingIcon} alt="Grooming Icon" onClick={() => handleMenuButtonClicked('store/services/grooming')} />
              </div>
              <p className="store-menu-button-title">Grooming</p>
            </div>
          </div>
          <div className="row">
            <div className="col store-menu-button-container">
              <div className='store-button-image-container'>
                <img className="store-menu-button-image" src={trainingIcon} alt="Dog Training Icon" onClick={() => handleMenuButtonClicked('store/services/training')} />
              </div>
              <p className="store-menu-button-title">Training</p>
            </div>
            <div className="col store-menu-button-container">
              <div className='store-button-image-container'>
                <img className="store-menu-button-image" src={adventureIcon} alt="Adventure Icon" onClick={() => handleMenuButtonClicked('store/services/adventure')} />
              </div>
              <p className="store-menu-button-title">Adventure</p>
            </div>
            <div className="col store-menu-button-container">
              <div className='store-button-image-container'>
                <img className="store-menu-button-image" src={shuttleIcon} alt="Food Icon" onClick={() => handleMenuButtonClicked('store/services/shuttle')} />
              </div>
              <p className="store-menu-button-title">Shuttle</p>
            </div>
          </div>
          <div className='row'>
            <div className="col store-menu-button-container">
              <div className='store-button-image-container'>
                <img className="store-menu-button-image" src={apparelsIcon} alt="Apparels Icon" onClick={() => handleMenuButtonClicked('coming-soon')} />
              </div>
              <p className="store-menu-button-title">Apparels</p>
            </div>
            <div className="col store-menu-button-container">
              <div className='store-button-image-container'>
                <img className="store-menu-button-image" src={homesIcon} alt="Homes Icon" onClick={() => handleMenuButtonClicked('coming-soon')} />
              </div>
              <p className="store-menu-button-title">Homes</p>
            </div>
            <div className="col store-menu-button-container">
              <div className='store-button-image-container'>
                <img className="store-menu-button-image" src={foodIcon} alt="Health & Wellness Icon" onClick={() => handleMenuButtonClicked('coming-soon')} />
              </div>
              <p className="store-menu-button-title">Food & Health</p>
            </div>
          </div>
        </div>
      </div>

      <div className="deals-container">
        {/* <h2>Special Deals</h2> */}
        <div className="deal-card-container">
          <div className="deal-card" onClick={() => handleDealCardClicked('store/deals/invite')}>
              <img src={wellnessIcon} alt="Deal 1" />
              <h3 className='deal-card-header'>Invite</h3>
              {/* <p>Share pawsitivity and earn rewards!</p> */}
              {/* <button>Shop Now</button> */}
          </div>
          <div className="deal-card" onClick={() => handleDealCardClicked('store/deals/gift-card')}>
              <img src={giftcardIcon} alt="Deal 2" />
              <h3 className='deal-card-header'>Gift Card</h3>
              {/* <p>Send your loved ones a gift card!</p> */}
              {/* <button>Shop Now</button> */}
          </div>
          <div className="deal-card" onClick={() => handleDealCardClicked('profile/friends')}>
              <img src={friendsIcon} alt="Deal 1" />
              <h3 className='deal-card-header'>Friends</h3>
              {/* <p>Share pawsitivity and earn rewards!</p> */}
              {/* <button>Shop Now</button> */}
          </div>
          <div className="deal-card" onClick={() => handleDealCardClicked('profile/wallet')}>
              <img src={walletIcon} alt="Deal 2" />
              <h3 className='deal-card-header'>Wallet</h3>
              {/* <p>Send your loved ones a gift card!</p> */}
              {/* <button>Shop Now</button> */}
          </div>
          <div className="deal-card" onClick={() => handleDealCardClicked('store/deals/impact')}>
              <img src={rescueIcon} alt="Deal 1" />
              <h3 className='deal-card-header'>Rescue</h3>
              {/* <p>Share pawsitivity and earn rewards!</p> */}
              {/* <button>Shop Now</button> */}
          </div>
        </div>
      </div>

      <div className="deals-container">
        <Link to="/store/deals/invite" className="invite-highlight">
            <div className="invite-highlight-card">
              <img className='invite-highlight-icon' src={inviteIcon}></img>
              <h3 className='invite-highlight-title'>Invite friends, earn $425 off any service</h3>
              <img src={arrowIcon} className='invite-highlight-button'></img>
            </div>
        </Link>
      </div>

      <hr className='deals-breakline'/>

      <div className='deals-container'>
        <Link to="/store/deals/impact" className="impact-highlight">
          <div className="impact-highlight-card">
            <div className='impact-highlight-card-items'>
              <h3 className='impact-highlight-number'>{totalAnimalsHelped}</h3>
              <h3 className='impact-highlight-paragraph'>Animals helped. Your support enables us to contribute to animal rescue and care.</h3>
              <div className='impact-highlight-action'>
                <h3 className='impact-highlight-button-title'>SEE MY IMPACT</h3>
                <img src={arrowIcon} className='impact-highlight-button'></img>
              </div>
            </div>
            <div className="impact-highlight-card-items">
              <img className='impact-highlight-icon' src={impactIcon}></img>
            </div>
          </div>
        </Link>
      </div>

      <Footer />

    </div>
  );
};

export default Store;
