import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../../StateProvider';
import './Cart.css'; // Make sure to include styles for the cart
import sanitizeDate from '../../Utilities/functions/SanitizeDate';
import { removeItemFromCart } from '../../Utilities/api';
import { WhiteBackButton } from '../../Utilities/components/Buttons';

import hotelIcon from '../../../assets/Landing/hotelIcon.png';
import daycareIcon from '../../../assets/Landing/daycareIcon.png';
import groomingIcon from '../../../assets/Landing/groomingIcon.png';
import adventureIcon from '../../../assets/Landing/adventureIcon.png';
import shuttleIcon from '../../../assets/Landing/shuttleIcon.png';
import trainingIcon from '../../../assets/Landing/trainingIcon.png';

const Cart = () => {
    const navigate = useNavigate();
    const { user, cart, setCart, cartTotal, cartTotalDiscount, refreshUserData, setBookingData } = useContext(StateContext);
    const [totalPrice, setTotalPrice] = useState(cartTotal);
    const [totalDiscount, setTotalDiscount] = useState(cartTotalDiscount);

    useEffect(() => {
        // Calculate the total price of the cart items
        const total = cart.reduce((acc, item) => acc + item.discountedPrice, 0);
        // Calculate the total discount of the cart items
        const totalOriginal = cart.reduce((acc, item) => acc + item.originalPrice, 0);
        const totalDiscountAmount = totalOriginal - total;

        setTotalPrice(total);
        setTotalDiscount(totalDiscountAmount);
    }, [cart]);

    const handleMenuButtonClicked = (page) => {
        navigate(`/${page}`);
        window.scrollTo(0, 0);
        setBookingData(prevData => ({
          ...prevData,
          bookingType: page
        }));
        localStorage.setItem('bookingType', page);
    }

    const removeFromCart = async (index, itemId) => {
        try {
            await removeItemFromCart(itemId); // Call the API to remove the item from the backend
            const newCart = [...cart];
            newCart.splice(index, 1); // Remove the item at the specified index
            setCart(newCart);
            refreshUserData(user.uid);
        } catch (error) {
            console.error('Error removing item from cart:', error);
        }
    };

    const CapitalizeFirstLetter = (text) => {
        const capitalize = str => {
            return str.charAt(0).toUpperCase() + str.slice(1);
        };

        return capitalize(text);
    };

    // Utility function to display additional services
    const displayAdditionalServices = (services) => {
        // Define a mapping of service keys to user-friendly names
        const serviceNames = {
            grooming: 'Grooming',
            training: 'Training',
            shuttle: 'Shuttle',
            adventure: 'Adventure',
            walk: 'Leash Walk'
        };

        // Filter the services object to find keys with true values
        // and map them to their user-friendly names
        return Object.entries(services)
            .filter(([key, value]) => value)
            .map(([key]) => serviceNames[key] || key);
    };

    // Utility function to display training details
    const displayTrainingDetails = (trainingOptions) => {
        // Mapping of training option keys to user-friendly names
        const trainingTypeNames = {
            puppy: 'Puppy Obedience',
            basic: 'Basic Obedience',
            behavior: 'Behavior Modification',
            akc: 'AKC Obedience',
        };

        // Getting the user-friendly name for the selected training type
        const trainingType = trainingTypeNames[trainingOptions.selectedTrainingType] || trainingOptions.selectedTrainingType;

        // Capitalize the first letter of session type ('group' or 'private')
        const sessionType = trainingOptions.selectedSessionType.charAt(0).toUpperCase() + trainingOptions.selectedSessionType.slice(1);

        return `${trainingType} - ${sessionType} Session`;
    };

    // Utility function to display shuttle details
    const displayShuttleDetails = (item) => {
        if (item.bookingInfo.selectedService === 'Airport') {
            return (
                <ul>
                    <li><strong>For</strong> {item.bookingInfo.selectedService}</li>
                    <li><strong>Pick Up</strong> {item.bookingInfo.pickUpLocation.split(',')[0]}</li>
                    <li><strong>Drop Off</strong> {item.bookingInfo.dropOffLocation.split(',')[0]}</li>
                    <li><strong>Flight Number</strong> {item.bookingInfo.flightNumber}</li>
                    <li><strong>Terminal</strong> {item.bookingInfo.terminal}</li>
                </ul>
            );
        }

        return (
            <ul>
                <li><strong>For</strong> {item.bookingInfo.selectedService}</li>
                <li><strong>Pick Up</strong> {item.bookingInfo.pickUpLocation.split(',')[0]}</li>
                <li><strong>Drop Off</strong> {item.bookingInfo.dropOffLocation.split(',')[0]}</li>
            </ul>
        );
    };

    // Utility function to display membership details
    const displayMembershipDetails = (membershipOptions) => {
        const membershipPackageNames = {
            Basic: 'Basic Membership',
            Premium: 'Premium Membership',
            VIP: 'VIP Membership'
        };

        const groomingPackageNames = {
            Basic: 'Basic Grooming',
            Premium: 'Premium Grooming',
            VIP: 'VIP Grooming'
        };

        const membershipPackage = membershipPackageNames[membershipOptions.selectedPackage];
        const groomingPackage = groomingPackageNames[membershipOptions.selectedGroomingPackage];

        return (
            <ul>
                <li>{membershipPackage}</li>
                {groomingPackage && <li>{groomingPackage}</li>}
            </ul>
        );
    };

    const handleCheckoutClicked = () => {
        if (cart.length > 0) {
            navigate(`/profile/checkout`);
            window.scrollTo(0, 0);
        }
    }

    const displayDateRange = (dateRange) => {
        const sanitizedDateRange = sanitizeDate(dateRange).split(' - ');
        if (sanitizedDateRange.length !== 2) {
            return "Invalid Date Range";
        }
    
        const [startDate, endDate] = sanitizedDateRange;
        
        if (startDate === endDate) {
            return startDate;
        }
    
        return `${startDate} - ${endDate}`;
    };

    const formatBookingType = (bookingType) => {
        if (bookingType.includes('Membership')) {
          return 'Membership';
        }
        return bookingType;
    };

    return (
        <div>
            <div className='cart-backdrop'>
                <div className='container'>
                    <WhiteBackButton />
                    <div className="cart-container">
                        {cart.length > 0 ? (
                            <div>
                                <ul className="cart-list">
                                    {cart.map((item, index) => (
                                        <li key={index} className="cart-item-container">
                                            <div className="cart-item-header-btn-container">
                                                {
                                                    item.bookingInfo.selectedPets && item.bookingInfo.selectedPets.length === 1
                                                        ? (
                                                            <h3 className='cart-item-header'>
                                                                <span className='cart-item-highlight'>{formatBookingType(item.bookingType)}</span> - <span className='cart-item-highlight2'>{item.bookingInfo.selectedPets[0].petName}</span>
                                                            </h3>
                                                        )
                                                        : (
                                                            <h3 className='cart-item-header'>
                                                                <span className='cart-item-highlight'>{item.bookingType}</span> - <span className='cart-item-highlight2'>{
                                                                    item.bookingInfo.selectedPets ? item.bookingInfo.selectedPets.map((pet, index, array) => {
                                                                        if (index === array.length - 1 && index !== 0) {
                                                                            return ` & ${pet.petName}`;
                                                                        } else if (index === array.length - 2) {
                                                                            return `${pet.petName}`;
                                                                        } else {
                                                                            return `${pet.petName}, `;
                                                                        }
                                                                    }) : ''
                                                                }</span>
                                                            </h3>
                                                        )
                                                }
                                                <button className="cart-remove-btn" onClick={() => removeFromCart(index, item.id)}>Remove</button>
                                            </div>

                                            <hr className='cart-breakline' />

                                            <div className='cart-item-details'>
                                                {item.bookingInfo.dateRange
                                                    ? <p className="cart-item-date"><strong>Date</strong> {displayDateRange(item.bookingInfo.dateRange)}</p>
                                                    : null
                                                }
                                                {/* <p className="cart-item-date"><strong>Date</strong> {displayDateRange(item.bookingInfo.dateRange)}</p> */}
                                                {item.bookingInfo.selectedTimeSlot
                                                    ? (<p className="cart-item-time"><strong>Time</strong> {item.bookingInfo.selectedTimeSlot}</p>)
                                                    : null
                                                }
                                                {item.bookingType === 'Grooming'
                                                    ? (
                                                        <div className='cart-item-options-details'>
                                                            <p className='cart-item-options-header'>Grooming Details</p>
                                                            <ul>
                                                                {/* <li>Package - {CapitalizeFirstLetter(item.bookingInfo.groomingOptions.selectedPackage)}</li> */}
                                                                <li>Pet Size - {CapitalizeFirstLetter(item.bookingInfo.groomingOptions.selectedSize)}</li>
                                                                {item.bookingInfo.groomingOptions.selectedHaircutStyle
                                                                    ? (
                                                                        <li>Hair Style - {CapitalizeFirstLetter(item.bookingInfo.groomingOptions.selectedHaircutStyle)}</li>
                                                                    )
                                                                    : null
                                                                }
                                                                {
                                                                    item.bookingInfo.groomingOptions.additionalServices
                                                                        ? (
                                                                            <>
                                                                                <li>Add Ons</li>
                                                                                <ul className="addons-list">
                                                                                    {item.bookingInfo.groomingOptions.additionalServices.map((service, index) => {
                                                                                        switch (service) {
                                                                                            case 'fleaTick':
                                                                                                return <li key={index}>Flea & Tick Treatment</li>;
                                                                                            case 'teethBrushing':
                                                                                                return <li key={index}>Teeth Brushing</li>;
                                                                                            case 'deshedding':
                                                                                                return <li key={index}>Deshedding</li>;
                                                                                            default:
                                                                                        }
                                                                                    })}
                                                                                </ul>
                                                                            </>
                                                                        )
                                                                        : null
                                                                }
                                                            </ul>
                                                        </div>
                                                    )
                                                    : null
                                                }

                                                {item.bookingType === 'Hotel'
                                                    ? (
                                                        <div className='cart-item-options-details'>
                                                            <p className='cart-item-options-header'>Details</p>
                                                            <ul>
                                                                <li>Diet - {CapitalizeFirstLetter(item.bookingInfo.foodPreference)}</li>
                                                                {/* <li>Additional - {item.bookingInfo.additionalServices}</li> */}
                                                            </ul>

                                                            {(item.bookingInfo.additionalServices && item.bookingInfo.additionalServices.length > 0)
                                                                ? (
                                                                    <div className='cart-item-options-details'>
                                                                        <p className='cart-item-options-header'>Additional Services</p>
                                                                        <ul>
                                                                            {displayAdditionalServices(item.bookingInfo.additionalServices).map((service, index) => (
                                                                                <li key={index}>{service}</li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                )
                                                                : null
                                                            }

                                                            {/* Display special notes */}
                                                            {item.bookingInfo.specialNotes && (
                                                                <div className='cart-item-special-notes'>
                                                                    <p className='cart-item-options-header'>Special Notes</p>
                                                                    <p className='cart-item-options-message'>"{item.bookingInfo.specialNotes}"</p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )
                                                    : null
                                                }

                                                {item.bookingType === 'Daycare'
                                                    ? (
                                                        <div className='cart-item-options-details'>

                                                            {item.bookingInfo.additionalServices.length > 0 && (
                                                                <div className='cart-item-options-details'>
                                                                    <p className='cart-item-options-header'>Additional Services</p>
                                                                    <ul>
                                                                        {displayAdditionalServices(item.bookingInfo.additionalServices).map((service, index) => (
                                                                            <li key={index}>{service}</li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            )}

                                                            {/* Display special notes */}
                                                            {item.bookingInfo.specialNotes && (
                                                                <div className='cart-item-special-notes'>
                                                                    <p className='cart-item-options-header'>Special Notes</p>
                                                                    <p className='cart-item-options-message'>"{item.bookingInfo.specialNotes}"</p>
                                                                </div>
                                                            )}

                                                        </div>
                                                    )
                                                    : null
                                                }

                                                {item.bookingType === 'Training'
                                                    ? (
                                                        <div className='cart-item-options-details'>

                                                            {item.bookingInfo.trainingOptions && (
                                                                <div className='cart-item-options-details'>
                                                                    <p className='cart-item-options-header'>Training Details</p>
                                                                    <p className='cart-item-options-message'>{displayTrainingDetails(item.bookingInfo.trainingOptions)}</p>
                                                                </div>
                                                            )}

                                                            {/* Display special notes */}
                                                            {item.bookingInfo.specialNotes && (
                                                                <div className='cart-item-special-notes'>
                                                                    <p className='cart-item-options-header'>Special Notes</p>
                                                                    <p className='cart-item-options-message'>"{item.bookingInfo.specialNotes}"</p>
                                                                </div>
                                                            )}

                                                        </div>
                                                    )
                                                    : null
                                                }

                                                {item.bookingType === 'Adventure'
                                                    ? (
                                                        <div className='cart-item-options-details'>

                                                            {item.bookingInfo.selectedPackage && (
                                                                <div className='cart-item-options-details'>
                                                                    <p className='cart-item-options-header'>Adventure Details</p>
                                                                    <p className='cart-item-options-message'>{item.bookingInfo.selectedPackage}</p>
                                                                </div>
                                                            )}

                                                            {/* Display special notes */}
                                                            {item.bookingInfo.specialNotes && (
                                                                <div className='cart-item-special-notes'>
                                                                    <p className='cart-item-options-header'>Special Notes</p>
                                                                    <p className='cart-item-options-message'>"{item.bookingInfo.specialNotes}"</p>
                                                                </div>
                                                            )}

                                                        </div>
                                                    )
                                                    : null
                                                }

                                                {item.bookingType === 'Shuttle'
                                                    ? (
                                                        <div className='cart-item-options-details'>
                                                            <p className='cart-item-options-header'>Shuttle Details</p>
                                                            {displayShuttleDetails(item)}

                                                            {/* Display special notes */}
                                                            {item.bookingInfo.specialNotes && (
                                                                <div className='cart-item-special-notes'>
                                                                    <p className='cart-item-options-header'>Special Notes</p>
                                                                    <p className='cart-item-options-message'>"{item.bookingInfo.specialNotes}"</p>
                                                                </div>
                                                            )}

                                                            {/* Display Emergency */}
                                                            {item.bookingInfo.emergencyContactNumber && (
                                                                <div className='cart-item-special-notes'>
                                                                    <p className='cart-item-options-header'>Emergency Contact</p>
                                                                    <ul>
                                                                        <li>{item.bookingInfo.emergencyContactName}</li>
                                                                        <li>{item.bookingInfo.emergencyContactNumber}</li>
                                                                    </ul>
                                                                </div>
                                                            )}

                                                        </div>
                                                    )
                                                    : null
                                                }

                                                {item.bookingType === 'Membership'
                                                    ? (
                                                        <div className='cart-item-options-details'>
                                                            <p className='cart-item-options-header'>Membership Details</p>
                                                            {displayMembershipDetails(item.bookingInfo.membershipOptions)}

                                                            {/* Display special notes */}
                                                            {item.bookingInfo.specialNotes && (
                                                                <div className='cart-item-special-notes'>
                                                                    <p className='cart-item-options-header'>Special Notes</p>
                                                                    <p className='cart-item-options-message'>"{item.bookingInfo.specialNotes}"</p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )
                                                    : null
                                                }

                                                {item.bookingType === 'Gift-card-purchase'
                                                    ? (
                                                        <div className='cart-item-options-details'>
                                                            <p className='cart-item-options-header'>Gift Card Details</p>
                                                            <ul>
                                                                <li>Amount - ${item.bookingInfo.amount}</li>
                                                                <li>Recipient Email - {item.bookingInfo.recipientEmail}</li>
                                                            </ul>
                                                        </div>
                                                    )
                                                    : null
                                                }

                                                {item.originalPrice === item.discountedPrice
                                                    ? (
                                                        <div className="cart-item-price-container">
                                                            <p className="cart-item-price"> ${item.originalPrice.toFixed(0)}</p>
                                                        </div>
                                                    )
                                                    : (
                                                        <div className="cart-item-price-container">
                                                            <p className="cart-item-discounted-price">
                                                                <span className='cart-item-original-price-strike'>${item.originalPrice.toFixed(0)}</span>
                                                                ${item.discountedPrice}
                                                            </p>
                                                        </div>
                                                    )
                                                }

                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ) : (
                            <div>
                                <h3 className='cart-empty-message'>Your cart is empty.</h3>
                                <div className='cart-menu'>
                                    <h3 className='cart-menu-header'>Discover our premium services and book now!</h3>
                                    {/* <div className="store-container"> */}
                                    <div className="container cart-menu-container">
                                        <div className="row">
                                            <div className="col cart-menu-button-container">
                                            <div className='cart-button-image-container'>
                                                <img className="cart-menu-button-image" src={hotelIcon} alt="Hotel Icon" onClick={() => handleMenuButtonClicked('store/services/hotel')} />
                                            </div>
                                            <p className="cart-menu-button-title">Hotel</p>
                                            </div>
                                            <div className="col cart-menu-button-container">
                                            <div className='cart-button-image-container'>
                                                <img className="cart-menu-button-image" src={daycareIcon} alt="Daycare Icon" onClick={() => handleMenuButtonClicked('store/services/daycare')} />
                                            </div>
                                            <p className="cart-menu-button-title">Daycare</p>
                                            </div>
                                            <div className="col cart-menu-button-container">
                                            <div className='cart-button-image-container'>
                                                <img className="cart-menu-button-image" src={groomingIcon} alt="Grooming Icon" onClick={() => handleMenuButtonClicked('store/services/grooming')} />
                                            </div>
                                            <p className="cart-menu-button-title">Grooming</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col cart-menu-button-container">
                                            <div className='cart-button-image-container'>
                                                <img className="cart-menu-button-image" src={trainingIcon} alt="Dog Training Icon" onClick={() => handleMenuButtonClicked('store/services/training')} />
                                            </div>
                                            <p className="cart-menu-button-title">Training</p>
                                            </div>
                                            <div className="col cart-menu-button-container">
                                            <div className='cart-button-image-container'>
                                                <img className="cart-menu-button-image" src={adventureIcon} alt="Adventure Icon" onClick={() => handleMenuButtonClicked('store/services/adventure')} />
                                            </div>
                                            <p className="cart-menu-button-title">Adventure</p>
                                            </div>
                                            <div className="col cart-menu-button-container">
                                            <div className='cart-button-image-container'>
                                                <img className="cart-menu-button-image" src={shuttleIcon} alt="Food Icon" onClick={() => handleMenuButtonClicked('store/services/shuttle')} />
                                            </div>
                                            <p className="cart-menu-button-title">Shuttle</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="cart-total-container">
                <h3 className='cart-total'>Total ${totalPrice.toFixed(2)}</h3>
                <h3 className='cart-savings'>Saving <span className='cart-savings-highlight'>(${totalDiscount.toFixed(2)})</span></h3>
                <button className="cart-checkout-btn" onClick={handleCheckoutClicked}>Checkout</button>
            </div>
        </div>
    );
};

export default Cart;
