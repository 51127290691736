import React, { useContext, useState, useEffect } from 'react';
import '../../Booking.css';
import './AdventureBooking.css';
import { StateContext } from '../../../../StateProvider';
import { FaInfoCircle } from 'react-icons/fa';
import Calendar from 'react-calendar';
import { tooltips } from './adventureTooltips';
import { getAdventureAvailabilityByMonth, fetchService } from '../../../../Utilities/api';
import SelectedCardDetails from './SelectedCardDetails';

function AdventureBooking() {
  const { user, setBookingData } = useContext(StateContext);
  const [adventureData, setAdventureData]= useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [datesSelected, setDatesSelected] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [availabilityCache, setAvailabilityCache] = useState({});
  const [tooltipVisibility, setTooltipVisibility] = useState({
    who: false,
    upcomingAdventures: false,
    notes: false,
  });

  const [adventureBookingData, setAdventureBookingData] = useState({
      selectedPets: [],
      dateRange: new Date(),
      showCalendar: false,
      foodPreference: '',
      specialNotes: '',
      pickupTime: '',
      dropoffTime: '',
      groomingOptions: [],
      trainingOptions: [],
      selectedPackage: '',
      additionalServices: {
          hotel: false,
          daycare: false,
          grooming: false,
          training: false,
          shuttle: false,
          adventure: false,
          walk: false
      }
  });
  
  useEffect(() => {
    const fetchAdventureService = async () => {
      try {
        const response = await fetchService('Adventure');
        setAdventureData(response);
      } catch (error) {
        console.error('Error fetching adventure services:', error);
      }
    };

    fetchAdventureService();
  }, []);

  useEffect(() => {
    const today = new Date();
    const currentMonth = today.getMonth() + 1; // Month in human-readable format (1-12)
    const year = today.getFullYear();
    const nextMonth = currentMonth === 12 ? 1 : currentMonth + 1; // Handle December to January transition
    const nextMonthYear = currentMonth === 12 ? year + 1 : year;

    const currentMonthKey = `${year}-${currentMonth}`;
    const nextMonthKey = `${nextMonthYear}-${nextMonth}`;

    const fetchAvailability = async () => {
        const [currentMonthData, nextMonthData] = await Promise.all([
            !availabilityCache[currentMonthKey] && getAdventureAvailabilityByMonth(currentMonth, year),
            !availabilityCache[nextMonthKey] && getAdventureAvailabilityByMonth(nextMonth, nextMonthYear)
        ]);

        setAvailabilityCache(prev => ({
            ...prev,
            ...(currentMonthData ? { [currentMonthKey]: currentMonthData } : {}),
            ...(nextMonthData ? { [nextMonthKey]: nextMonthData } : {})
        }));
    };

    fetchAvailability();

    // Set booking data on mount
    setBookingData(prevData => ({
        ...prevData,
        bookingInfo: adventureBookingData
    }));
  }, [adventureBookingData]);

  // Can select multiple pets
  const togglePetSelection = (petId, petName) => {
    setAdventureBookingData(prevBookingData => {
        let newSelectedPets;

        // Check if the petId is already included in the selectedPets array
        const isAlreadySelected = prevBookingData.selectedPets.some(selectedPet => selectedPet.petId === petId);

        if (isAlreadySelected) {
            // Filter out the petId if it's already selected
            newSelectedPets = prevBookingData.selectedPets.filter(selectedPet => selectedPet.petId !== petId);
        } else {
            // Add a new object with petId and petName if it's not already selected
            newSelectedPets = [...prevBookingData.selectedPets, { petId, petName }];
        }

        return {
            ...prevBookingData,
            selectedPets: newSelectedPets
        };
    });
  };

  const showTooltip = (key) => {
    setTooltipVisibility({ ...tooltipVisibility, [key]: true });
  };

  const hideTooltip = (key) => {
    setTooltipVisibility({ ...tooltipVisibility, [key]: false });
  };

  const handleCardClick = (adventure, index) => {
    setSelectedCard(index);
    setIsModalVisible(true);
    setAdventureBookingData((prevData) => ({ ...prevData, selectedPackage: adventure.title, dateRange: [new Date(adventure.date), new Date(adventure.date)] }))
  };
  
  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleFoodPreferenceChange = (preference) => {
    setAdventureBookingData(prevData => ({ ...prevData, foodPreference: preference }));
  };

  const handleServiceChange = (serviceName) => {
    const currentStatus = adventureBookingData.additionalServices[serviceName];
    const updatedServices = {
        ...adventureBookingData.additionalServices,
        [serviceName]: !currentStatus
    };
    setAdventureBookingData(prevData => ({ ...prevData, additionalServices: updatedServices }));
  };    

  const handleNotesChange = (e) => {
    setAdventureBookingData((prevData) => ({ ...prevData, specialNotes: e.target.value }));
  };

  return (
    <div className="booking-backdrop">
      <div className="booking-container">
        <div className="booking-header">
          <h3>Pawsible Adventure</h3>
        </div>

        <div className="booking-content">
          <div className="booking-section">
            <h2>
              Pet Guests <FaInfoCircle onMouseOver={() => showTooltip('who')} onMouseOut={() => hideTooltip('who')} />
            </h2>
            {tooltipVisibility.who && <div className="tooltip" dangerouslySetInnerHTML={{ __html: tooltips.who }}></div>}
            <div className="pet-selection">
              {user && user.familyMembers && user.familyMembers.map((pet) => (
                (pet.type === 'Dog' || pet.type === 'Cat') && (
                  <div key={pet.id}>
                    <button
                      className={`pet-button ${adventureBookingData.selectedPets.some(selectedPet => selectedPet.petId === pet.id) ? 'pet-button-selected' : ''}`} 
                      // className={`pet-button-${adventureBookingData.selectedPets.includes(pet.id) ? 'selected' : ''}`}
                      onClick={() => togglePetSelection(pet.id, pet.name)}
                    >
                      <img src={pet.image} alt={pet.name} className="pet-photo" />
                      <p className="pet-name">{pet.name}</p>
                      <p className="pet-type">{pet.type}</p>
                    </button>
                  </div>
                )
              ))}
            </div>
          </div>

          <div className="booking-section">
            <h2>
              Upcoming Events <FaInfoCircle onMouseOver={() => showTooltip('upcomingAdventures')} onMouseOut={() => hideTooltip('upcomingAdventures')} />
            </h2>
            {tooltipVisibility.upcomingAdventures 
              ? <div className="tooltip" dangerouslySetInnerHTML={{ __html: tooltips.upcomingAdventures }}></div>
              : (
                  <div>
                    <p className='adventure-no-event-message'>No events are scheduled at the moment.</p>
                    <p className='adventure-no-event-message'>Stay tuned for exciting adventures coming soon!</p>
                  </div>
                )
            }

          <div className="adventure-card-container">
            {Array.isArray(adventureData) && adventureData.map((adventure, index) => (
              <div
                key={index}
                className={`adventure-card ${selectedCard === index ? 'selected' : ''}`}
                onClick={() => handleCardClick(adventure, index)}
              >
                <img src={adventure.image} alt={adventure.title} className="adventure-image" />
                <div className='adventure-info-section'>
                  <h3 className="adventure-title">{adventure.title}</h3>
                  <p className="adventure-description">{adventure.description}</p>
                  <p className="adventure-date">{adventure.date}</p>
                </div>
              </div>
            ))}
          </div>

            {isModalVisible && (
                <div className="booking-overlay">
                    <div className="booking-modal modal-visible">
                    <span className="booking-close-button" onClick={() => handleCloseModal()}>
                        &times;
                    </span>
                    <SelectedCardDetails adventure={adventureData[selectedCard]} />
                    </div>
                </div>
            )}
          </div>

          <div className="booking-section">
            <h2>Meal Plans <FaInfoCircle onMouseOver={() => showTooltip('food')} onMouseOut={() => hideTooltip('food')} /></h2>
            {tooltipVisibility.food && <div className="tooltip" dangerouslySetInnerHTML={{ __html: tooltips.food }}></div>}
            <div className="food-preference">
                <button 
                    className={adventureBookingData.foodPreference === 'own' ? 'food-preference-button-selected' : 'food-preference-button'}
                    onClick={() => handleFoodPreferenceChange('own')}
                >
                    Own
                </button>
                <button 
                    className={adventureBookingData.foodPreference === 'basic' ? 'food-preference-button-selected' : 'food-preference-button'}
                    onClick={() => handleFoodPreferenceChange('basic')}
                >
                    Basic
                </button>
                <button 
                    className={adventureBookingData.foodPreference === 'premium' ? 'food-preference-button-selected' : 'food-preference-button'}
                    onClick={() => handleFoodPreferenceChange('premium')}
                >
                    Premium
                </button>
            </div>
          </div>

          <div className="booking-section">
            <div className='additional-services'>
                <h2>Additional Services <FaInfoCircle onMouseOver={() => showTooltip('services')} onMouseOut={() => hideTooltip('services')} /></h2>
                {tooltipVisibility.services && <div className="tooltip" dangerouslySetInnerHTML={{ __html: tooltips.services }}></div>}
                <div className='booking-additional-button-container'>
                    <button 
                        className={`service-button-${adventureBookingData.additionalServices.hotel ? 'selected' : ''}`}
                        onClick={() => handleServiceChange('hotel')}
                    >
                        Hotel
                    </button>
                    <button 
                        className={`service-button-${adventureBookingData.additionalServices.daycare ? 'selected' : ''}`}
                        onClick={() => handleServiceChange('daycare')}
                    >
                        Daycare
                    </button>
                    <button 
                        className={`service-button-${adventureBookingData.additionalServices.grooming ? 'selected' : ''}`}
                        onClick={() => handleServiceChange('grooming')}
                    >
                        Grooming
                    </button>

                    <button 
                        className={`service-button-${adventureBookingData.additionalServices.training ? 'selected' : ''}`}
                        onClick={() => handleServiceChange('training')}
                    >
                        Training
                    </button>

                    <button 
                        className={`service-button-${adventureBookingData.additionalServices.shuttle ? 'selected' : ''}`}
                        onClick={() => handleServiceChange('shuttle')}
                    >
                        Shuttle
                    </button>
                </div>
            </div>
          </div>

          <div className="booking-section">
            <h2>
              Special Requests <FaInfoCircle onMouseOver={() => showTooltip('notes')} onMouseOut={() => hideTooltip('notes')} />
            </h2>
            {tooltipVisibility.notes && <div className="tooltip" dangerouslySetInnerHTML={{ __html: tooltips.notes }}></div>}
            <textarea
              placeholder=""
              value={adventureBookingData.specialNotes}
              onChange={handleNotesChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdventureBooking;