import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../index.js';
import { sendVerificationCode, verifyTwilioCode, updateUserPhoneNumber, uploadFileToS3, addFamilyMember, updateStoreLocation } from '../../Utilities/api/index.js';
import dogBreeds from '../../Utilities/data/dogBreeds.js';
import { updateProfileComplete } from '../../Utilities/api/index.js';

import personPlaceholder from '../../../assets/Dashboard/Profile/personPlaceholder.png';
import addIcon from '../../../assets/Dashboard/Profile/addIcon.png';
import dogPlaceholder from '../../../assets/Dashboard/Profile/dogPlaceholder.png';
import catPlaceholder from '../../../assets/Dashboard/Profile/catPlaceholder.png';
import './AddProfileModal.css'; // Import the CSS file

const AddProfileModal = ({ showAddProfileModal, handleCloseModal, handleSaveProfile }) => {
    const navigate = useNavigate();

    const { user, setUser, refreshUserData, setLoading } = useContext(StateContext);
    const [phone, setPhone] = useState('');
    const [newPhone, setNewPhone] = useState('');
    const [phoneVerificationButtonText, setPhoneVerificationButtonText] = useState('Verify')
    const [phoneVerificationButtonResendText, setPhoneVerificationButtonResendText] = useState('Resend')
    const [phoneVerificationSent, setPhoneVerificationSent] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [selectedStore, setSelectedStore] = useState('');
    const [familyMembers, setFamilyMembers] = useState([]);
    const [isAddingFamilyMember, setIsAddingFamilyMember] = useState(false);
    const [errors, setErrors] = useState({});
    const [errorMessages, setErrorMessages] = useState([]);
    const [uploadedImage, setUploadedImage] = useState(null);
    const [fileData, setFileData] = useState({
        imageFile: null,
        vaccinationFile: null
    });
    const [memberDetails, setMemberDetails] = useState({
        userId: '',
        name: '',
        type: 'Dog',
        breed: 'Not Specified', // Default breed
        age: 0,
        dob: '2024-01-01',
        daycare: false,
        vaccination: '',
        image: '',
    });

    const placeholderImages = {
        Human: personPlaceholder,
        Dog: addIcon,
        Cat: addIcon,
    };

    useEffect(() => {
        if (user && user.info) {
            setMemberDetails(prev => ({ ...prev, userId: user.uid }));
            setPhone(user.info.phone || '');
            setFamilyMembers(user.familyMembers || []);
            setSelectedStore(user.storeLocation || '');
        }
    }, [user]);

    const handleSave = async () => {
        const newErrors = {};
        if (!phone) {
            newErrors.phone = true;
        }
        if (!selectedStore) {
            newErrors.store = true;
        }
        if (familyMembers.length < 2) {
            newErrors.familyMembers = true;
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors); // set error states for fields
            return Object.keys(newErrors).length === 0; // return true if no errors
        } else {
            setLoading(true);
            const updatedProfile = {
                phone: phone,
                selectedStore,
                familyMembers,
            };

            // Save profile
            handleSaveProfile(updatedProfile);

            try {
                // Update profileComplete status to true in the database
                await updateStoreLocation(user.uid, selectedStore);
                await updateProfileComplete(user.uid, true);
                await refreshUserData(user.uid);
                console.log('Profile marked as complete.');
            } catch (error) {
                console.error('Error marking profile complete:', error);
            }

            // Close the modal after successful save
            handleCloseModal();
            // Clear errors after successful save
            setErrors({});
            navigate('/profile');
            setLoading(false);
        }
    };

    const formatPhoneNumber = (phoneNumber) => {
        if (!phoneNumber || phoneNumber.length !== 10) return phoneNumber;
        const areaCode = phoneNumber.slice(0, 3);
        const middlePart = phoneNumber.slice(3, 6);
        const lastPart = phoneNumber.slice(6, 10);
        return `${areaCode}-${middlePart}-${lastPart}`;
    };

    const handleChangeButtonClick = () => {
        setPhoneVerificationButtonText('Verify');
    }

    const handlePhoneUpdate = async () => {
        if (newPhone) {
            try {
                setPhoneVerificationButtonText('Verifying');
                // setTimeout(() => setPhoneVerificationButtonText('Verify'), 2000); // Reset button text after 2 seconds
                // Call the Twilio API to send the verification code
                await sendVerificationCode(newPhone);
                setPhoneVerificationSent(true);
                console.log('Verification code sent successfully.');
            } catch (error) {
                console.error('Error sending verification code:', error);
            }
        }
    };

    // Validate form before submission
    const validateFamilyMemberForm = () => {
        const newErrors = {};

        if (!memberDetails.name) {
            newErrors.name = true; // mark as invalid
        }
        if (!memberDetails.type) {
            newErrors.type = true; // mark as invalid
        }
        if (memberDetails.type !== 'Human' && !memberDetails.breed) {
            newErrors.breed = true; // mark as invalid
        }
        if (memberDetails.breed === 'Not Specified') {
            newErrors.breed = true; // mark as invalid
        }
        if (memberDetails.type !== 'Human' && !memberDetails.dob && memberDetails.dob === '2024-01-01') {
            newErrors.dob = true; // mark as invalid
        }
        if (memberDetails.dob === '2024-01-01') {
            newErrors.dob = true; // mark as invalid
        }
        if (!fileData.vaccinationFile) {
            newErrors.vaccination = true; // mark as invalid
        }

        setErrors(newErrors); // set error states for fields
        return Object.keys(newErrors).length === 0; // return true if no errors
    };

    const handleAddFamilyMemberSubmit = async () => {
        setLoading(true);
        if (validateFamilyMemberForm()) {
            try {
                if (fileData.imageFile) {
                    const imageUrl = await uploadFileToS3(fileData.imageFile);
                    memberDetails.image = imageUrl;
                } else {
                    // Set placeholder image if no image is uploaded
                    memberDetails.image = getPlaceholderImageUrl(memberDetails.type);
                }
        
                if (fileData.vaccinationFile) {
                    const vaccinationUrl = await uploadFileToS3(fileData.vaccinationFile);
                    memberDetails.vaccination = vaccinationUrl;
                }
                
                await addFamilyMember(memberDetails);
                await refreshUserData(user.uid);
                setIsAddingFamilyMember(false); // Switch back to the profile modal
            } catch (error) {
                console.error('Error adding family member:', error);
            }
        }
        setLoading(false);
    };

    const handleAddFamilyMember = () => {
        setIsAddingFamilyMember(true); // Switch to family member addition mode
    };

    const handleBackButton = () => {
        setIsAddingFamilyMember(false); // Switch to family member addition mode
    };

    const handleVerificationResend = async () => {
        setPhoneVerificationButtonResendText('Woof!');
        setTimeout(() => setPhoneVerificationButtonResendText('Resend'), 3000); // Reset button text after 2 seconds
    }

    const handleVerifyCode = async () => {
        setLoading(true);
        if (verificationCode && newPhone) {
            try {
                // Call the Twilio API to verify the code
                const result = await verifyTwilioCode(newPhone, verificationCode, user.uid);
                if (result.success) {
                    setPhoneVerificationButtonText('Verified');
                    await updateUserPhoneNumber(user.uid, newPhone);
                    refreshUserData(user.uid);

                } else {
                    console.error('Verification failed:', result.error);
                }
            } catch (error) {
                console.error('Error verifying code:', error);
            }
        }
        setLoading(false);
    };

    const getPlaceholderImageUrl = (type) => placeholderImages[type] || placeholderImages.Human;
    const handleNameChange = (e) => setMemberDetails(prev => ({ ...prev, name: e.target.value }));
    const handleTypeChange = (e) => setMemberDetails(prev => ({ ...prev, type: e.target.value }));
    const handleBreedChange = (e) => setMemberDetails(prev => ({ ...prev, breed: e.target.value }));
    const handleDobChange = (e) => setMemberDetails(prev => ({ ...prev, dob: e.target.value }));
    const handleImageChange = (e) => {
        if (e.target.files[0]) {
            setFileData(prev => ({ ...prev, imageFile: e.target.files[0] }));

            const imageUrl = URL.createObjectURL(e.target.files[0]);
            setUploadedImage(imageUrl);
        }
    };
    const handleVaccinationUpload = (e) => {
        if (e.target.files[0]) {
            setFileData(prev => ({ ...prev, vaccinationFile: e.target.files[0] }));
        }
    };

    return (
        <>
            {showAddProfileModal && (
                <div className="add-profile-access-overlay">
                    <div className="add-profile-modal show" tabIndex="-1" role="dialog">
                        <div className="modal-dialog add-profile-add-pet-modal-dialog" role="document">
                            <div className="modal-content">
                                {!isAddingFamilyMember ? (
                                    <>
                                        {/* Main Profile Setup Modal */}
                                        <div className="-header">
                                            <h5 className="-title add-pet-modal-title">Complete Your Profile</h5>
                                        </div>
                                        <div className="modal-body add-pet-modal-body">
                                            <p className='add-pet-modal-message'>Let's set up your profile to get started!</p>

                                            {/* Phone Number Input */}
                                            {phoneVerificationButtonText === 'Verify' ? (
                                                <div className="form-group add-profile-input-field">
                                                    <label className='add-pet-modal-label' htmlFor="phone">Phone Number</label>
                                                    <div className="add-profile-input-button-container">
                                                        <input
                                                            type="text"
                                                            id="phone"
                                                            value={newPhone}
                                                            onChange={(e) => setNewPhone(e.target.value)}
                                                            className={`form-control ${errors.phone ? 'input-error' : ''}`}
                                                            placeholder={phone || "Enter Phone Number"}
                                                        />
                                                        <button onClick={handlePhoneUpdate}>
                                                            {phoneVerificationButtonText}
                                                        </button>
                                                    </div>
                                                </div>
                                            ) : phoneVerificationButtonText === 'Verifying' ? (
                                                <div className="form-group add-profile-input-field">
                                                    <label className='add-pet-modal-label' htmlFor="phone">Confirm Code</label>
                                                    <input
                                                        type="text"
                                                        id="phone"
                                                        value={verificationCode}
                                                        onChange={(e) => setVerificationCode(e.target.value)}
                                                        className="form-control"
                                                        placeholder={verificationCode || "Enter Code"}
                                                    />
                                                    <button className='btn btn-secondary' onClick={handleVerificationResend}>{phoneVerificationButtonResendText}</button>
                                                    <button className='btn btn-secondary btn-close-blue' onClick={handleVerifyCode}>Verify</button>
                                                </div>
                                            ) : (
                                                <div className="form-group add-profile-input-field">
                                                    <label className='add-pet-modal-label' htmlFor="phone">Verified Number</label>
                                                    <p>{formatPhoneNumber(phone)}</p>
                                                    <button className='btn btn-secondary' onClick={handleChangeButtonClick}>
                                                        Change
                                                    </button>
                                                </div>
                                            )}

                                            {/* Store Location Selection */}
                                            <div className="form-group add-profile-input-field">
                                                <label className='add-pet-modal-label' htmlFor="store">Store Location</label>
                                                <select
                                                    id="store"
                                                    value={selectedStore}
                                                    onChange={(e) => setSelectedStore(e.target.value)}
                                                    className={`form-control ${errors.store ? 'input-error' : ''}`}
                                                >
                                                    <option value="">Select Store</option>
                                                    <option value="DTLA">Downtown LA</option>
                                                    <option value="Anaheim">Anaheim</option>
                                                </select>
                                            </div>

                                            {/* Add Family Members (Pets) */}
                                            <div className="add-profile-family-member-section">
                                                {user && user.familyMembers && user.familyMembers.map((member, index) => (
                                                    <div key={index} className="add-profile-family-member">
                                                        <img 
                                                            className={`add-profile-family-member-image` }
                                                            src={member.image || getPlaceholderImageUrl(member.type)} 
                                                            alt={`${member.type} Placeholder`} 
                                                        />
                                                        <h3 className='add-profile-family-member-name'>{member.name}</h3>
                                                        <p className='add-profile-family-member-type'>{member.type}</p>
                                                    </div>
                                                ))}
                                                <div className="add-profile-family-member" onClick={handleAddFamilyMember}>
                                                    <img 
                                                        className={`add-profile-family-member-image ${errors.familyMembers ? 'input-error' : ''}`}
                                                        src={addIcon} 
                                                        alt="Add New" 
                                                    />
                                                    <h3 className='add-profile-family-member-name'>Add</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-primary" onClick={handleSave}>
                                                Save
                                            </button>
                                            <button type="button" className="btn btn-secondary btn-close-red" data-dismiss="modal" onClick={handleCloseModal}>
                                                Close
                                            </button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {/* Family Member Addition Modal */}
                                        <div className="-header">
                                            <h5 className="-title add-pet-modal-title">Add New Member</h5>
                                        </div>
                                        <div className="modal-body add-pet-modal-body">
                                            <div className="form-group add-profile-input-field-two">
                                                {/* <p className='add-pet-modal-message'>Let's create a profile for your new family member!</p> */}
                                                {/* Family member addition form */}
                                                <div className="image-upload-container">
                                                    <img 
                                                        className='add-pet-profile-image' 
                                                        src={uploadedImage || getPlaceholderImageUrl(memberDetails.type)}
                                                        alt={`${memberDetails.type} Placeholder`} 
                                                    />
                                                    <label htmlFor="image-upload" className="add-pet-image-upload-label">
                                                        <input type="file" id="image-upload" className={`image-upload ${errors.image ? 'input-error' : ''}`} onChange={handleImageChange} />
                                                    </label>
                                                </div>

                                                <label className='add-pet-modal-label' htmlFor="name">Name</label>
                                                <input type="text" className={`form-control ${errors.name ? 'input-error' : ''}`} id="name" value={memberDetails.name} onChange={handleNameChange} />

                                                <label className='add-pet-modal-label' htmlFor="type">Type</label>
                                                <select id="type" className={`form-control ${errors.type ? 'input-error' : ''}`} value={memberDetails.type} onChange={handleTypeChange}>
                                                    <option value="Human">Human</option>
                                                    <option value="Dog">Dog</option>
                                                    <option value="Cat">Cat</option>
                                                </select>

                                                {/* Conditional Fields for Pets */}
                                                {memberDetails.type !== 'Human' && (
                                                    <>
                                                        {/* Breed Selection */}
                                                        <div className="form-group">
                                                            <label className='add-pet-modal-label' htmlFor="family-member-breed">Breed</label>
                                                            <select
                                                                id="family-member-breed"
                                                                className={`form-control ${errors.breed ? 'input-error' : ''}`}
                                                                value={memberDetails.breed}
                                                                onChange={handleBreedChange}
                                                            >
                                                                <option value="Not Specified">Not Specified</option>
                                                                {Object.keys(dogBreeds).sort().map(breed => (
                                                                    <option key={breed} value={breed}>{breed}</option>
                                                                ))}
                                                            </select>
                                                        </div>

                                                        {/* Date of Birth */}
                                                        <div className="form-group">
                                                            <label className='add-pet-modal-label' htmlFor="family-member-dob">Date of Birth</label>
                                                            <input
                                                                type="date"
                                                                id="family-member-dob"
                                                                className={`form-control ${errors.dob ? 'input-error' : ''}`}
                                                                value={memberDetails.dob}
                                                                onChange={handleDobChange}
                                                            />
                                                        </div>

                                                        {/* Vaccination Upload */}
                                                        <div className="form-group">
                                                            <label className='add-pet-modal-label' htmlFor="vaccination">Vaccination Records</label>
                                                            <input type="file" className={`form-control ${errors.vaccination ? 'input-error' : ''}`} id="vaccination" onChange={handleVaccinationUpload} />
                                                            <p className='add-pet-record-message'>*Records will be verified in a timely manner once uploaded.</p>
                                                        </div>
                                                    </>
                                                )}

                                                <div className='modal-footer'>
                                                    <button className="btn btn-primary" onClick={handleAddFamilyMemberSubmit}>
                                                        Add Member
                                                    </button>
                                                    <button className="btn btn-secondary btn-close-red" onClick={handleBackButton}>
                                                        Back
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default AddProfileModal;
