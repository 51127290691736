import React, { useState, useEffect } from 'react';
import './MobileAccessPrompt.css';
import whiteLogo from '../../../../assets/logo_white.png';

const MobileAccessPrompt = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Adjust width as needed
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1200); // Update based on viewport width
    };

    window.addEventListener('resize', handleResize);
    
    // Initial check
    setIsMobile(window.innerWidth <= 1200);
    setLoading(false); // Stop loading after the check

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (loading) return null;

  // If not mobile, show the overlay
  if (!isMobile) {
    return (
      <div className="mobile-access-overlay">
        <div className="mobile-access-content">
          <img src={whiteLogo} alt="Pawsible Logo" className="browser-prompt-logo" />
          <h2>Mobile Access Only</h2>
          <p>Please access the app using a mobile device. The desktop view will be available soon.</p>
          <p>www.pawsible.app</p>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default MobileAccessPrompt;
